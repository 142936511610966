import COS from "cos-js-sdk-v5";
import http from "@/api/request";
import { CustomResponse } from "@/api";
import { Toast } from "@/ui";
import { randomString } from "@/common/tool";
import { getSuffix } from "./file";

const BUCKET = "voicepost-1301841918";
const REGION = "ap-shanghai";

interface UploadAuthorization {
  credentials: {
    sessionToken: string;
    tmpSecretId: string;
    tmpSecretKey: string;
  };
  expiration: string;
  requestId: string;
  startTime: number;
  expiredTime: number;
}

export interface UploadResult {
  path: string;
  name: string;
}

export interface StsResponse extends CustomResponse {
  data?: unknown;
}

const cos = new COS({
  getAuthorization(options, callback) {
    http.get<StsResponse>("/live/sts").then((res) => {
      const data = res.data;
      if (data.error == 0) {
        let authorization: UploadAuthorization | null = null;
        if (typeof data.data === "string") {
          try {
            authorization = JSON.parse(data.data) as UploadAuthorization;
          } catch (e) {
            console.error("parse authorization failed: ", e);
            Toast("上传配置解析失败");
          }
        } else {
          authorization = data.data as UploadAuthorization;
        }
        if (authorization) {
          const credentials = authorization.credentials;
          callback({
            TmpSecretId: credentials.tmpSecretId,
            TmpSecretKey: credentials.tmpSecretKey,
            XCosSecurityToken: credentials.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: authorization.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: authorization.expiredTime, // 时间戳，单位秒，如：1580000900
          });
        } else {
          console.error("credentials invalid");
          Toast("上传配置格式不正确");
        }
      } else {
        Toast(data.msg || "获取上传配置失败");
      }
    });
  },
});
export const getRandomFileName = function (path: string) {
  return `${Date.now()}_${randomString(16)}${getSuffix(path)}`;
};

export const uploadObject = function (
  file: COS.UploadBody,
  filePath: string,
  onProgress?: (progress: { percent: number; speed: number }) => void
) {
  return new Promise<UploadResult>((resolve, reject) => {
    cos.putObject(
      {
        Bucket: BUCKET /* 必须 */,
        Region: REGION /* 存储桶所在地域，必须字段 */,
        Key: filePath /* 必须 */,
        StorageClass: "STANDARD",
        Body: file, // 上传文件对象
        onProgress: (info) => {
          const percent = Math.round(info.percent * 10000) / 100;
          const speed = Math.round((info.speed / 1024 / 1024) * 100) / 100;
          typeof onProgress === "function" &&
            onProgress({
              percent,
              speed,
            });
        },
      },
      function (err, data) {
        if (err) {
          Toast("上传文件出错");
          reject(err);
        } else {
          resolve({ path: `https://${data.Location}`, name: filePath });
        }
      }
    );
  });
};

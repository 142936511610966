/**
 * 上传文件api
 */

import http from "@/api/request";
import { HOST } from "@/config";
import { CustomResponse } from "@/api/type";

export interface uploadFileOptions {
  file: File;
  hideLoading?: boolean;
}

export interface UploadApi {
  uploadFile: (options: uploadFileOptions) => Promise<string>;
  uploadFileToQCloud: (
    options: uploadFileOptions
  ) => Promise<{ path: string; local_path: string }>;
}

export interface UploadResponse extends CustomResponse {
  path?: string;
}

export interface UploadQCloundResponse extends CustomResponse {
  path?: string;
  local_path: string;
}

const uploadApi: UploadApi = {
  uploadFile(options: uploadFileOptions) {
    const formData = new FormData();
    formData.append("file", options.file);
    return http
      .post<UploadResponse>("/live/upload", formData, {
        transformRequest: (data, headers) => {
          // 去除post请求默认的Content-Type
          delete headers?.["Content-Type"];
          return data;
        },
      })
      .then((res) => {
        const data = res.data;
        if (data && data.path) {
          return HOST + data.path;
        } else {
          return Promise.reject(data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },
  uploadFileToQCloud(options: uploadFileOptions) {
    const formData = new FormData();
    formData.append("file", options.file);
    return http
      .post<UploadQCloundResponse>("/live/upload-to-qcloud", formData, {
        transformRequest: (data, headers) => {
          // 去除post请求默认的Content-Type
          delete headers?.["Content-Type"];
          return data;
        },
      })
      .then((res) => {
        const data = res.data;
        if (data.error == 0) {
          const { path, local_path } = data;
          return {
            path: "https://" + path,
            local_path,
          };
        } else {
          return Promise.reject(data.msg);
        }
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });
  },
};
export default uploadApi;

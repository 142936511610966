import { Modal } from "@/ui";

export const isEmpty = function (str: unknown) {
  return str === undefined || str === null || str === "" || str === false;
};
export const getDoubleText = function (str: string) {
  return str.length == 1 ? 0 + str : str;
};

export const isArray = function (arr: unknown) {
  return Object.prototype.toString.call(arr) === "[object Array]";
};
export const modalTip = function (content: string) {
  return new Promise<void>((resolve) => {
    Modal({
      title: "提示",
      content,
      cancelBtn: false,
      onConfirm: () => {
        resolve();
      },
    });
  });
};

export const randomString = function (len: number) {
  len = len || 32;
  const chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
  const maxPos = chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
};

/**
 * 获取当前网页环境
 * @return env 1浙里办APP 2支付宝浙里办小程序 3浏览器 4微信小程序
 */
export const getWebEnv = function (): number {
  let res = 3;
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.indexOf("dtdreamweb") !== -1) {
    res = 1;
  } else if (ua.indexOf("miniprogram") !== -1) {
    if (ua.indexOf("alipay") !== -1) {
      res = 2;
    } else {
      res = 4;
    }
  }
  return res;
};

/**
 * base64转文件
 * @param base64
 */
export const base64ToFile = function (base64: string, fileName: string): File {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1];
  const bStr = atob(arr[1]);
  let n = bStr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bStr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
};

/**
 * blob转文件
 * @param blob
 * @param fileName
 * @param mime
 */
export const blobToFile = function (
  blob: Blob,
  fileName: string,
  mime?: string
): File {
  return new File([blob], fileName, { type: mime });
};

/**
 * 获取文件大小展示文案
 * @param size
 */
export const getFileSizeText = function (size: number): string {
  const kb = 1024,
    mb = 1024 * 1024,
    gb = 1024 * 1024 * 1024;
  if (size < kb) {
    return `${size}B`;
  } else if (size < mb) {
    return `${Math.round((size / kb) * 100) / 100}K`;
  } else if (size < gb) {
    return `${Math.round((size / mb) * 100) / 100}M`;
  } else {
    return `${Math.round((size / gb) * 100) / 100}G`;
  }
};

/**
 * 根据常量值获取常量名称
 * @param list 常量列表数组
 * @param value 常量值
 * @param valKey 常量值key
 * @param titleKey 常量名称key
 * **/
export const getValueTitle = function <T, P = T>(
  list: Array<Record<string, T | P>>,
  value: T,
  valKey = "value",
  titleKey = "text"
): P | undefined {
  let res = null;
  if (list) {
    res = list.find((item) => item[valKey] == value);
  }
  return res ? (res[titleKey] as P) : undefined;
};

export const getDate = function (date: string): Date | null {
  if (date) {
    const arr = date.split(/[-:\s]/).map(Number);
    const arg = arr.concat(new Array(6 - arr.length).fill(0));
    arg[1] = arg[1] && arg[1] > 0 ? arg[1] - 1 : 0;
    return new Date(arg[0], arg[1], arg[2], arg[3], arg[4], arg[5]);
  } else {
    return null;
  }
};

export const isUrl = function (str: string) {
  return /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/.test(
    str
  );
};

/**
 * 在数字前面自动补0
 * @param {*} num 数字
 * @param {*} length 返回的字符长度
 * @returns {string}
 * e.g:
 * paddingZero(1, 2) => "01"
 * paddingZero(1, 3) => "001"
 */
export const paddingZero = function (
  num: string | number,
  length: number
): string {
  const str = num + "";
  return str.length >= length ? str : paddingZero("0" + str, length);
};

export const getTimeDurationText = function (duration: number) {
  const second = Math.round(duration);
  let h = 0,
    m = 0,
    s = 0;
  if (second < 60) {
    s = second;
  } else if (second < 60 * 60) {
    m = Math.floor(second / 60);
    s = second % 60;
  } else {
    h = Math.floor(second / (60 * 60));
    const min = second % (60 * 60);
    m = Math.floor(min / 60);
    s = min % 60;
  }
  s = Math.round(s);
  if (h > 0) {
    return (
      paddingZero(h, 2) + ":" + paddingZero(m, 2) + ":" + paddingZero(s, 2)
    );
  } else {
    return paddingZero(m, 2) + ":" + paddingZero(s, 2);
  }
};

import { App, Plugin } from "vue";
import userApi, { UserApi } from "./modules/user";
import uploadApi, { UploadApi } from "./modules/upload";
import loginApi, { LoginApi } from "./modules/login";
import recordApi, { RecordApi } from "./modules/record";
import activityApi, { ActivityApi } from "./modules/activity";
export type ApiPlugin = Plugin &
  UserApi &
  UploadApi &
  LoginApi &
  RecordApi &
  ActivityApi;
const apiPlugin: ApiPlugin = {
  install(app: App) {
    app.config.globalProperties.$api = apiPlugin;
  },
  ...userApi,
  ...uploadApi,
  ...loginApi,
  ...recordApi,
  ...activityApi,
};
export default apiPlugin;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $api: ApiPlugin;
  }
}
export * from "./type";

import { User } from "@/api/modules/user";
import { RootState, UserState } from "@/store/type";
import { ActionContext } from "vuex";
import { AuthToken, getToken } from "@/common/storage";
import apiPlugin from "@/api";
import { getPartUserInfoItem } from "@/common/userinfo";

export default {
  state: {
    token: null,
    user: null,
    uid: null,
    subscribe: -1,
  },
  getters: {
    token: (state: UserState) => state.token,
    user: (state: UserState) => state.user,
    uid: (state: UserState) => state.uid,
    subscribe: (state: UserState) => state.subscribe,
  },
  mutations: {
    setToken(state: UserState, value: AuthToken | null) {
      state.token = value;
    },
    setUser(state: UserState, value: User | null) {
      state.user = value;
    },
    setUid(state: UserState, value: number | null) {
      state.uid = value;
    },
    setSubscribe(state: UserState, value: number) {
      state.subscribe = value;
    },
  },
  actions: {
    setUserState(context: ActionContext<UserState, RootState>) {
      const token = getToken();
      console.log("set user state.");
      if (token) {
        context.commit("setToken", token);
        return apiPlugin.getUser().then((res) => {
          res.real_name = getPartUserInfoItem(res.real_name, "name");
          res.mobile = getPartUserInfoItem(res.mobile, "mobile");
          context.commit("setUser", res);
          context.commit("setUid", res.id);
        });
      } else {
        return Promise.reject({ error: 1 });
      }
    },
  },
};

import { clearStorage } from "@/common/storage";
import { APP_ID } from "@/config";
import { Modal } from "@/ui";

let loading = false;

const auth = function (uri?: string) {
  if (loading) return;
  loading = true;
  clearStorage();
  const redirect_uri = encodeURIComponent("https://app.qihe.live/auth/");
  uri = uri || location.pathname;
  const state = encodeURIComponent(`${location.origin}${uri}`);
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APP_ID}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=${state}#wechat_redirect`;
  location.replace(url);
};

export const showAuth = function (content?: string) {
  content = content || "您尚未登录，请先登录授权！";
  Modal({
    title: "提示",
    content,
    confirmBtn: "授权",
    cancelBtn: false,
    onConfirm: () => {
      auth();
    },
  });
};

export default auth;

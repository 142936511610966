import { PropType } from "vue";
import { ScrollListProps } from "./type";

export default {
  /* 接口url */
  url: {
    type: String,
    required: true,
  },
  /* 接口query */
  query: String,
  /* 额外请求项 */
  requestConfig: Object as PropType<ScrollListProps["requestConfig"]>,
  // 列表为空时显示文字
  emptyText: {
    type: String,
    default: "暂无数据",
  },
  // 没有更多内容是显示文字 传null不显示
  noMoreText: {
    type: String,
    default: "已经到底啦",
  },
  pageSize: {
    type: Number,
    default: 10,
  },
};

import { TOKEN_EXPIRE } from "../config";
import { LoginResponse, User } from "@/api";
import store from "@/store";

export const TOKEN_KEY = "token";
export const UID_KEY = "uid";
export const INFO_KEY = "info";
export const SUBSCRIBE_KEY = "subscribe";
export type AuthToken = {
  timestamp: number;
  value: string;
} | null;
let token: AuthToken = null;

export const getUserId = function (): number {
  let id = store.getters.uid;
  if (!id) {
    const sid = localStorage.getItem(UID_KEY);
    if (sid) {
      id = Number(sid);
      store.commit("setUid", id);
    }
  }
  return id || 0;
};

export const getSubscribe = function (): number {
  let subscribe = store.getters.subscribe;
  if (subscribe === -1) {
    const s = localStorage.getItem(SUBSCRIBE_KEY);
    if (s) {
      subscribe = Number(s);
      store.commit("setSubscribe", subscribe);
    }
  }
  return subscribe || 0;
};

export const getUser: () => User | null = function () {
  let user = store.getters.user;
  if (!user) {
    const u = getStorage<User>(INFO_KEY);
    if (u) {
      user = u;
      store.commit("setUser", user);
    }
  }
  return user;
};

export const getToken: () => AuthToken | null = function () {
  if (!token) {
    console.log("Read token from storage.");
    token = getStorage<AuthToken>(TOKEN_KEY);
  }
  //判断token是否过期 过期返回null
  if (token && Date.now() - token.timestamp > TOKEN_EXPIRE) {
    token = null;
  }
  // token = {
  //   timestamp: Date.now(),
  //   value: "0ee81b096d94821dc314d0eaf9c98507",
  // };
  // localStorage.setItem(UID_KEY, "1");
  // localStorage.setItem(SUBSCRIBE_KEY, "1");
  return token;
};

export const setToken = function (data: LoginResponse) {
  if (data.token) {
    const temp = {
      timestamp: Date.now(),
      value: data.token,
    };
    const value = JSON.stringify(temp);
    token = temp;
    store.commit("setToken", token);
    localStorage.setItem(TOKEN_KEY, value);
    const user = data.user;
    if (user) {
      store.commit("setUid", user.id);
      store.commit("setUser", user);
      store.commit("setSubscribe", data.subscribe);
      localStorage.setItem(UID_KEY, user.id + "");
      localStorage.setItem(SUBSCRIBE_KEY, data.subscribe + "");
      localStorage.setItem(INFO_KEY, JSON.stringify(user));
    }
  }
};

export const getStorage: <T>(key: string) => T | null = function <T>(
  key: string
) {
  let result: T | null = null;
  const storage = String(localStorage.getItem(key));
  try {
    result = JSON.parse(storage) as T;
  } catch (e) {
    console.log(`Storage for ${key} parse failed.`);
  }
  return result;
};

export const clearStorage = function (): void {
  token = null;
  localStorage.removeItem(TOKEN_KEY);
};


import { defineComponent } from "vue";

export default defineComponent({
  name: "PageLoading",
  props: {
    text: {
      type: String,
      default: "加载中",
    },
  },
});

import http from "../request";

export interface Result {
  error: number;
  msg: string;
}

export interface Params {
  mobile: string;
  code?: string;
}

export interface LoginApi {
  getCode: (data: Params) => Promise<Result>;
  login: (data: Params) => Promise<Result>;
}

const loginApi: LoginApi = {
  getCode(data) {
    return http
      .post<Result>("/weixin/wx-user/get-verify-code", data)
      .then((res) => res.data);
  },
  login(data) {
    return http
      .post<Result>("/weixin/wx-user/bind-mobile", data)
      .then((res) => res.data);
  },
};

export default loginApi;

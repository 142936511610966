import { PropType } from "vue";
import { FormAreaProps } from "@/components/form-area/type";

export default {
  config: {
    type: Object as PropType<FormAreaProps["config"]>,
    default() {
      return {};
    },
  },
  height: String,
  buttonText: {
    type: String,
    default: "提交",
  },
  value: {
    type: Object as PropType<FormAreaProps["value"]>,
    default() {
      return {};
    },
  },
  size: {
    type: String as PropType<FormAreaProps["size"]>,
    default: "default",
  },
};

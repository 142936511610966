import http from "../request";
import { Activity, CustomResponse, EntityListData } from "../type";

export interface Record {
  id: number;
  activity_id: number;
  user_id: number;
  alias: string;
  img: string;
  voice_file: string;
  local_path: string;
  media_id: string;
  qr_code: string;
  wish_words: string;
  duration: number;
  status: number;
  activity?: Activity;
  create_time: string;
  update_time: string;
}

export interface GenerateResponse extends CustomResponse {
  img: string;
}

type RecordData = Omit<
  Record,
  | "id"
  | "alias"
  | "media_id"
  | "qr_code"
  | "wish_words"
  | "create_time"
  | "update_time"
>;

export interface RecordApi {
  getRecentRecord: (alias: string) => Promise<Record | null>;
  insertRecord: (data: RecordData) => Promise<Record>;
  getRecordById: (id: number) => Promise<Record>;
  getCardImage: (id: number) => Promise<string>;
}

const TABLE_URL = "/wish-record";

const recordApi: RecordApi = {
  getRecentRecord(alias) {
    const url = `${TABLE_URL}?pageSize=1&filter[status]=0&filter[alias]=${alias}`;
    return http.get<EntityListData<Record>>(url).then((res) => {
      const list = res.data.data;
      return list.length > 0 ? list[0] : null;
    });
  },

  insertRecord(data) {
    return http.post<Record>(TABLE_URL, data).then((res) => res.data);
  },
  getRecordById(id) {
    return http.get<Record>(`${TABLE_URL}/${id}`).then((res) => res.data);
  },
  getCardImage(id) {
    return http
      .get<GenerateResponse>(`/wish-record/generate?id=${id}&is_share=1`)
      .then((res) => {
        if (res.data.error == 0) {
          return res.data.img;
        } else {
          return Promise.reject(res.data.msg);
        }
      });
  },
};

export default recordApi;


import { defineComponent, ref, reactive, toRef, computed, watch } from "vue";
import halfDateProps from "./props";
import { Range } from "@/ui/picker/type";

export default defineComponent({
  name: "halfDate",
  props: { ...halfDateProps },
  setup(props, { emit }) {
    const date = ref("");
    const time = ref("");
    const options = reactive<Range[]>(["上午", "下午"]);
    const modelValue = toRef(props, "modelValue");
    const value = computed<Array<string | number> | null>({
      get() {
        let res = null;
        if (date.value && time.value) {
          const index = options.indexOf(time.value);
          if (index >= 0) {
            res = [date.value, index];
          }
        }
        return res;
      },
      set(val) {
        if (val && val.length > 0) {
          date.value = String(val[0]);
          time.value = String(options[Number(val[1])]);
        } else {
          date.value = "";
          time.value = "";
        }
      },
    });
    const setValue = () =>
      (value.value = modelValue.value ? [...modelValue.value] : null);
    setValue();
    watch(modelValue, setValue);
    watch(value, () => emit("update:modelValue", value.value));
    return {
      date,
      time,
      options,
    };
  },
});

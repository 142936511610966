import { PropType } from "vue";
import { CRadioProps } from "@/ui/radio/type";

export const RadioProps = {
  options: {
    type: Array as PropType<CRadioProps["options"]>,
    default: "selector",
  },
  modelValue: Number,
};

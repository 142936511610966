
import { defineComponent } from "vue";
import TabBarProps from "./props";
import CIcon from "@/ui/icon";

export default defineComponent({
  name: "TabBar",
  components: { CIcon },
  props: { ...TabBarProps },
  methods: {
    tabClick(i: number) {
      this.$emit("change", i);
    },
  },
});

import { defineComponent } from "vue";
import scrollViewProps from "./props";

export default defineComponent({
  name: "ScrollView",
  props: { ...scrollViewProps },
  data() {
    return {};
  },
  methods: {
    handleScroll(e: Event) {
      const dom = e.currentTarget as HTMLElement;
      if (dom.scrollTop + dom.offsetHeight >= dom.scrollHeight - this.offset) {
        this.$emit("scrollToLower");
      }
    },
  },
  render() {
    return (
      <div class="scroll-area scroll-view" onScroll={this.handleScroll}>
        {this.$slots.default?.()}
      </div>
    );
  },
});


import { defineComponent, toRef, ref, computed, reactive } from "vue";
import carProps from "./props";
import { CModalInstance } from "@/ui";
import { FormValue } from "@/components/form-area/type";
import formArea, { FormAreaInstance } from "@/components/form-area";
import { randomString } from "@/common/tool";

export default defineComponent({
  name: "CarAdd",
  components: { formArea },
  props: { ...carProps },
  setup(props, { emit }) {
    const num = ref("");
    const modal = ref<CModalInstance | null>(null);
    const formRef = ref<FormAreaInstance | null>(null);
    const initial = reactive<FormValue>({});
    const list = computed<FormValue[]>({
      get() {
        let res: FormValue[] = [];
        const value = toRef(props, "modelValue").value;
        if (value) {
          res = [...value];
        }
        return res;
      },
      set(val) {
        console.log("set:", val);

        emit("update:modelValue", val);
      },
    });

    const showAdd = () => {
      initial.value = {};
      modal.value?.show();
    };
    const removeCar = (i: number) => {
      const arr = [...list.value];
      arr.splice(i, 1);
      list.value = arr;
    };

    const confirmAdd = (hide: () => void) => {
      formRef.value?.submit().then((form: FormValue) => {
        console.log("confirmAdd:", form);
        let key = randomString(16);
        let temp = { key, ...form };
        list.value = [...list.value, temp];
        hide();
      });
    };

    let labelStyle = "padding: 0;";

    return {
      num,
      list,
      modal,
      removeCar,
      showAdd,
      confirmAdd,
      formRef,
      initial,
      form: [
        {
          component: "input",
          key: "num",
          label: "车牌号码",
          must: true,
          placeholder: "请输入车牌号码",
          rules: [
            {
              rule: { required: true, message: "请输入车牌号码" },
              title: "必填",
              type: 1,
            },
          ],
          type: "text",
          labelStyle,
        },
        {
          component: "input",
          key: "name",
          label: "联系人",
          must: true,
          placeholder: "请输入联系人姓名",
          rules: [
            {
              rule: { required: true, message: "请输入联系人姓名" },
              title: "必填",
              type: 1,
            },
          ],
          type: "text",
          labelStyle,
        },
        {
          component: "input",
          key: "tel",
          label: "联系方式",
          must: true,
          placeholder: "请输入联系方式",
          rules: [
            {
              rule: { required: true, message: "请输入联系方式" },
              title: "必填",
              type: "tel",
            },
          ],
          type: "tel",
          labelStyle,
        },
      ],
    };
  },
});

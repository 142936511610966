import http from "../request";
import { UID_KEY } from "@/common/storage";

export interface User {
  id: number;
  open_id: string;
  platform: number;
  mobile: string;
  avatar: string;
  uname: string;
  real_name: string;
  create_time: string;
  update_time: string;
}

export interface UserApi {
  getUser: () => Promise<User>;
}

const userApi: UserApi = {
  getUser() {
    const uid = localStorage.getItem(UID_KEY);
    return http.get<User>(`/weixin/wx-user/${uid}`).then((res) => res.data);
  },
};

export default userApi;

import { defineComponent } from "vue";
import { RadioProps } from "@/ui/radio/props";
import { RadioItem } from "@/ui/radio/type";
import mixin from "@/ui/form/mixin";
import "./style/radio.less";

export default defineComponent({
  name: "CRadio",
  mixins: [mixin],
  props: { ...RadioProps },
  methods: {
    selectItem(item: RadioItem): void {
      this.$emit("update:modelValue", item.value);
      this.notifyCheck();
    },
    getRadioItemClass(item: RadioItem): string {
      let base = "radio-item";
      if (item.value === this.modelValue) {
        base += " radio-select";
      }
      return base;
    },
  },
  render() {
    return (
      <div class="radio-group">
        {this.options.map((item) => (
          <div
            class={this.getRadioItemClass(item)}
            onClick={() => this.selectItem(item)}
          >
            <div class="radio-icon"></div>
            <div class="radio-txt">{item.text}</div>
          </div>
        ))}
      </div>
    );
  },
});

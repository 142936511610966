import { Coordinate } from "./type";

/**
 * 判断一个触点滑动方向
 * @param movedX X轴滑动距离
 * @param movedY Y轴滑动距离
 * @return direction 0触点未滑动 1向上滑动 2向下滑动 3向左滑动 4向右滑动
 */
export const getTouchMoveDirection = function (
  movedX: number,
  movedY: number
): number {
  let res = 0;
  if (movedX !== 0 || movedY !== 0) {
    // 获取滑动与Y轴角度正切绝对值
    const tan = Math.abs(movedX) / Math.abs(movedY);
    // 方向滑动与Y轴角度在正负30度以内都算垂直方向 超出30度算水平方向
    const minTan = Math.tan((30 * Math.PI) / 180);
    if (tan > minTan) {
      res = movedX > 0 ? 4 : 3;
    } else {
      res = movedY > 0 ? 2 : 1;
    }
  }
  return res;
};

/**
 * 获取两点之间的距离
 * @param start 起点
 * @param end 终点
 * @return distance 距离
 */

export const getDistance = function (
  start: Coordinate,
  end: Coordinate
): number {
  return Math.hypot(end.x - start.x, end.y - start.y);
};


import { defineComponent } from "vue";
import mixin from "../form/mixin";
import uploadProps from "./props";
import { CUploadData, FileValueItem, FileItem } from "./type";
import { getFileSizeText, modalTip } from "@/common/tool";
import { UploadResult, uploadObject, getRandomFileName } from "./cos";
import {
  ICON_PREFIX,
  getFileTypeIcon,
  getUrlFileName,
  previewFile,
} from "./file";

export default defineComponent({
  name: "CUpload",
  mixins: [mixin],
  props: { ...uploadProps },
  data(): CUploadData {
    return {
      list: [],
      stateIcon: {
        loading: "loading",
        success: "check-circle-filled",
        error: "error-circle-filled",
      },
      prefix: ICON_PREFIX,
    };
  },
  computed: {
    limitText(): string {
      return getFileSizeText(this.size);
    },
  },
  watch: {
    modalValue() {
      this.setFileList();
    },
  },
  created(): void {
    this.setFileList();
  },
  methods: {
    setFileList() {
      const val = this.modelValue;
      if (val) {
        let files: FileValueItem[] = [];
        if (typeof val === "string") {
          try {
            files = JSON.parse(val);
          } catch (e) {
            const urls = val.split(",");
            files = urls.map((url) => {
              return {
                url,
                fileName: getUrlFileName(url),
              };
            });
          }
        } else {
          files = [...val] as FileValueItem[];
        }
        this.list = files.map((f) => {
          const item: FileItem = {
            ...f,
            state: "success",
            type: getFileTypeIcon(f.url),
            name: getUrlFileName(f.url),
          };
          if (f.size) {
            item.sizeText = getFileSizeText(f.size);
          }
          return item;
        });
      } else {
        this.list = [];
      }
    },
    selectFile(e: Event) {
      const input = e.currentTarget as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        const size = this.size;
        let flag = true;
        for (let i = 0, l = input.files.length; i < l; i++) {
          const file = input.files[i];
          if (file.size > size) {
            modalTip(
              `您选择的第${i + 1}文件过大（最大不能超过${
                this.limitText
              }），请重新选择！`
            );
            flag = false;
            break;
          }
        }
        if (flag) {
          const arr: FileItem[] = [];
          const pro: Array<Promise<UploadResult>> = [];
          for (let i = 0, l = input.files.length; i < l; i++) {
            const file = input.files[i];
            const path = getRandomFileName(file.name);
            pro.push(uploadObject(file, path));
            arr.push({
              url: "",
              fileName: file.name,
              size: file.size,
              sizeText: getFileSizeText(file.size),
              state: "loading",
              type: getFileTypeIcon(file.name),
              name: path,
            });
          }
          this.list.push(...arr);
          Promise.all(pro).then((files) => {
            files.forEach((f) => {
              const i = this.list.findIndex((item) => item.name == f.name);
              if (i >= 0) {
                this.list[i].url = f.path;
                this.list[i].state = "success";
              }
            });
            this.dispatchChange();
          });
        }
      }
    },
    dispatchChange() {
      const arr: FileValueItem[] = this.list
        .filter((item) => item.state === "success")
        .map((item) => {
          const f: FileValueItem = {
            url: item.url,
            fileName: item.fileName,
          };
          if (item.size) {
            f.size = item.size;
          }
          return f;
        });
      this.$emit("update:modelValue", arr);
    },
    operate(i: number) {
      this.$showActionSheet({
        itemList: ["预览", "删除"],
        fn: (tab: number) => {
          if (tab == 0) {
            previewFile(this.list[i]);
          } else {
            this.list.splice(i, 1);
            this.dispatchChange();
          }
        },
      });
    },
  },
});

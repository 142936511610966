import { EntityState, ListUpdateState } from "@/store/type";
import { FormValue } from "@/components/form-area/type";

export default {
  state: {
    apply: null,
    key: null,
    updateState: null,
    activity: false,
  },
  getters: {
    apply: (state: EntityState) => state.apply,
    key: (state: EntityState) => state.key,
    updateState: (state: EntityState) => state.updateState,
    activity: (state: EntityState) => state.activity,
  },
  mutations: {
    setApply(state: EntityState, value: FormValue | null) {
      state.apply = value;
    },
    setKey(state: EntityState, value: string | null) {
      state.key = value;
    },
    setUpdateState(state: EntityState, value: ListUpdateState | null) {
      state.updateState = value;
    },
    setActivity(state: EntityState, value: EntityState["activity"]) {
      state.activity = value;
    },
  },
  actions: {},
};

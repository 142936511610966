import { PropType } from "vue";
import { CUploadProps } from "@/ui/upload/type";

export default {
  modelValue: {
    type: Array as PropType<CUploadProps["modelValue"]>,
  },

  /* 图片文件大小限制 */
  size: {
    type: Number,
    default: 10 * 1024 * 1024,
  },
  /* 文件数量限制 */
  limit: {
    type: [Number, String],
    default: 1,
  },
  auto: {
    type: Boolean,
    default: true,
  },
};

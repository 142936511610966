import { defineComponent, h, Teleport, Transition } from "vue";
import calendar from "@/ui/calendar/calendar.vue";
import { DateObject } from "@/ui/calendar/type";
import { PickerProps } from "./props";
import {
  RangeItem,
  Range,
  MultiArray,
  PickerData,
  ColumnChangeValue,
  RegionOptionItem,
} from "./type";
import PickerColumn from "./picker-column";
import CButton from "../button";
import { getDoubleText, isEmpty } from "@/common/tool";
import { RegionMap } from "@/ui/select/type";
import region from "@/ui/picker/city";
import "./style/picker.less";

export default defineComponent({
  name: "CPicker",
  components: {
    calendar,
    CButton,
    PickerColumn,
    Transition,
  },
  props: { ...PickerProps },
  data(): PickerData {
    return {
      index: 0,
      idx: [],
      list: [],
      show: false,
      visible: false,
      ms: false,
      linked: false, // multiSelector 是否联动选择
    };
  },
  computed: {
    modalClass(): string {
      const base = "c-cover-content";
      return `${base}${this.ms ? " cover-content-show" : ""}`;
    },
    type(): string {
      return this.mode === "selector" ? "selector" : "multiSelector";
    },
    key(): string {
      const mode = this.mode;
      let res = this.rangeKey || "";
      switch (mode) {
        case "region":
          res = "value";
          break;
      }
      return res;
    },
  },
  watch: {
    range() {
      this.setList();
    },
  },
  created(): void {
    this.setList();
  },
  methods: {
    setIndex() {
      const type = this.type;
      if (type == "selector") {
        const value = this.modelValue as Range;
        const list = this.list as Array<Range>;
        const i = this.getIndex(list, value);
        if (i >= 0) {
          this.index = i;
        }
      } else if (type == "multiSelector") {
        const value = this.modelValue as Array<Range>;
        const list = this.list as MultiArray<Range>;
        const len = list.length;
        const index = Array(len).fill(0);
        if (Array.isArray(value)) {
          list.forEach((item, i) => {
            if (value[i]) {
              const j = this.getIndex(item, value[i]);
              if (j >= 0) {
                index[i] = j;
              }
            }
          });
        }
        // 联动选择 计算二级选项
        if (this.linked) {
          const item = list[0][index[0]];
          if (typeof item === "object") {
            this.list[1] = item.children || [];
          }
        }
        this.idx = index;
      }
    },
    getIndex(list: Array<Range>, value: Range): number {
      return list.findIndex((item) => {
        if (typeof item === "object") {
          let val: RangeItem;
          if (typeof value !== "object") {
            const key = this.key || "value";
            val = {};
            val[key] = value;
          } else {
            val = value;
          }
          return Object.keys(val).every((key) => val[key] === item[key]);
        } else {
          return item === value;
        }
      });
    },
    setList(): void {
      const mode = this.mode;
      switch (mode) {
        case "time":
          this.list = [
            new Array(24).fill(0).map((item, i) => getDoubleText(i + "")),
            new Array(60).fill(0).map((item, i) => getDoubleText(i + "")),
          ];
          break;
        case "region": {
          const { province, city, county } = this.getRegionList();
          this.list = [province, city, county];
          break;
        }
        case "multiSelector": {
          const range = this.range;
          if (range && range.length > 0) {
            const item = range[0];
            if (Object.prototype.toString.call(item) === "[object Array]") {
              this.list = range;
            } else {
              // 一维数组 每个item 有children属性的情况 视为联动选择
              const item = [...range] as Range[];
              this.list = [item, []];
              this.linked = true;
            }
          } else {
            this.list = [];
          }
          break;
        }
        default:
          this.list = this.range || [];
      }
    },
    getRegionList() {
      const res: RegionMap = { province: [], city: [], county: [] };
      const value = (this.modelValue as Array<Range>) || [];
      const idx = this.idx;
      res.province = region.map((r) => {
        const p: Range = {
          value: r.value,
          id: r.id,
        };
        return p;
      });
      let pi = !isEmpty(idx[0])
        ? idx[0]
        : value[0]
        ? this.getIndex(res.province, value[0])
        : 0;
      pi = pi >= 0 ? pi : 0;
      const cityList = region[pi]?.children || [];
      res.city = cityList.map((r) => {
        const c: Range = {
          value: r.value,
          id: r.id,
        };
        return c;
      });
      let ci = !isEmpty(idx[1])
        ? idx[1]
        : value[1]
        ? this.getIndex(res.city, value[1])
        : 0;
      ci = ci >= 0 ? ci : 0;
      const countyList = cityList[ci]?.children || [];
      res.county = countyList.map((r) => {
        const c: Range = {
          value: r.value,
          id: r.id,
        };
        return c;
      });
      return res;
    },
    showModal(): void {
      this.setIndex();
      this.show = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.visible = true;
          this.ms = true;
        }, 0);
      });
    },
    hideModal(): void {
      this.ms = false;
      setTimeout(() => {
        this.show = false;
      }, 300);
    },
    confirm(): void {
      const type = this.type;
      let value;
      if (type == "selector") {
        const index = this.index;
        const list = this.list as Array<Range>;
        value = list[index];
      } else if (type == "multiSelector") {
        value = [] as Array<RegionOptionItem>;
        const list = this.list as MultiArray<Range>;
        const idx = this.idx;
        list.forEach((item, i) => {
          value?.push(item[idx[i]]);
        });
        if (this.mode === "region") {
          const list = [...(value || [])];
          value = list.map((item: RegionOptionItem) => item.value);
        }
      }
      if (value !== null) {
        this.$emit("update:modelValue", value);
      }
      this.hideModal();
    },
    handleUpdate(i: number, e: number): void {
      this.idx[i] = e;
      if (this.mode === "region") {
        this.setList();
        switch (i) {
          case 0:
            this.idx[1] = 0;
            this.idx[2] = 0;
            break;
          case 1:
            this.idx[2] = 0;
            break;
        }
      } else if (this.mode === "multiSelector" && this.linked && i === 0) {
        const list = this.list as MultiArray<Range>;
        const item = list[0][e];
        if (typeof item === "object") {
          this.list[1] = item.children || [];
        }
      }
      const val: ColumnChangeValue = {
        column: i,
        value: e,
      };
      this.$emit("columnChange", val);
    },
    selectDate(val: DateObject): void {
      this.$emit("update:modelValue", val.dateStr);
      this.hideModal();
    },
    renderPickerModal() {
      return h(
        Teleport,
        { to: "body" },
        <div class="c-cover picker-modal" v-show={this.show}>
          <transition name="fade">
            <div
              class="c-cover-back"
              onClick={this.hideModal}
              v-show={this.ms}
            ></div>
          </transition>
          <div class={this.modalClass}>
            {this.mode === "date"
              ? this.renderCalendarContent()
              : this.renderPickerContent()}
          </div>
        </div>
      );
    },
    renderCalendarContent() {
      return (
        <calendar
          change={true}
          selected={this.modelValue}
          onChange={this.selectDate}
          class="picker-calendar"
        ></calendar>
      );
    },
    renderPickerContent() {
      return this.visible ? (
        <div class="picker-modal-box">
          <c-icon
            name="close"
            class="picker-modal-close"
            onClick={this.hideModal}
          ></c-icon>
          <h3 class="picker-modal-title">{this.title}</h3>
          <div class="picker-modal-columns">{this.renderPickerColumn()}</div>
          <div class="picker-confirm">
            <c-button onClick={this.confirm}>确定</c-button>
          </div>
        </div>
      ) : null;
    },
    renderPickerColumn() {
      const type = this.type;
      if (type === "selector") {
        return (
          <div class="picker-columns-area">
            <picker-column
              onUpdate={(e: number) => (this.index = e)}
              index={this.index}
              class="picker-column-item"
              range={this.list}
              rangeKey={this.key}
            />
          </div>
        );
      } else if (type === "multiSelector") {
        return (
          <div class="picker-columns-area">
            {this.list.map((r, i) => (
              <picker-column
                onUpdate={(e: number) => this.handleUpdate(i, e)}
                index={this.idx[i]}
                range={r}
                rangeKey={this.key}
                class="picker-column-item"
              />
            ))}
          </div>
        );
      } else {
        return null;
      }
    },
  },
  render() {
    return (
      <div class="picker" onClick={this.showModal}>
        {this.$slots.default?.()}
        {this.renderPickerModal()}
      </div>
    );
  },
});


import { defineComponent } from "vue";
import FormAreaProps from "./props";
import { MOBILE_REG } from "./index";
import {
  FormAreaData,
  FormItemProp,
  FormItem,
  RelyConfig,
  FormValue,
} from "@/components/form-area/type";
import { FormRules, LabelRule } from "@/ui/form/type";
import { CFormInstance } from "@/ui/form";
const defaultKeyDemo: Record<string, string> = {
  personPhotoImage:
    "https://static-1302345365.cos.ap-nanjing.myqcloud.com/mini/avatar_demo_mini.jpg",
  idPhotoImage:
    "https://static-1302345365.cos.ap-nanjing.myqcloud.com/mini/idcard_front_mini.jpg",
  idPhotoContraryImage:
    "https://static-1302345365.cos.ap-nanjing.myqcloud.com/mini/idcard_back_mini.jpg",
};
const ID_CARD_REG =
  "(^[1-9]\\d{5}(18|19|([23]\\d))\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}[0-9Xx]$)|(^[1-9]\\d{5}\\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\\d{3}$)";

export default defineComponent({
  name: "FormArea",
  props: { ...FormAreaProps },
  data(): FormAreaData {
    return {
      form: {},
      rely: {},
      items: [],
    };
  },
  computed: {
    rules(): FormRules {
      const res: FormRules = {};
      const config = this.config;
      config.forEach((item) => {
        const key = item.key;
        if (item.rules && item.rules.length > 0) {
          res[key] = item.rules.map((r) => r.rule);
        }
        if (
          item.component === "input" &&
          (item.type === "idcard" || item.type === "tel")
        ) {
          let rule: LabelRule;
          if (item.type === "idcard") {
            rule = {
              regex: ID_CARD_REG,
              message: "请输入正确的身份证号码",
            };
          } else {
            rule = {
              regex: MOBILE_REG,
              message: "请输入正确的联系方式",
            };
          }
          if (res[key]) {
            res[key].push(rule);
          } else {
            res[key] = [rule];
          }
        }
      });
      return res;
    },
  },
  watch: {
    config() {
      this.setRely();
    },
    value() {
      this.setForm();
    },
  },
  created(): void {
    this.setForm();
    this.setRely();
  },
  methods: {
    handleChange(value: unknown, item: FormItem) {
      const rely = this.rely;
      if (rely[item.key]) {
        this.setItems();
      }
    },
    getItemProps(item: FormItem): FormItemProp {
      const prop: FormItemProp = {};
      for (let key in item) {
        if (
          ["component", "key", "label", "rules", "rely", "must"].indexOf(
            key
          ) === -1
        ) {
          prop[key] = item[key];
        }
      }
      if (item.need) {
        prop.nv = this.form[item.need.key];
      }
      return prop;
    },
    submit(): Promise<FormValue> {
      return new Promise((resolve, reject) => {
        const form: CFormInstance = this.$refs.form as CFormInstance;
        const error = form.validate();
        if (error) {
          this.$toast(error);
          reject(error);
        } else {
          const data: FormValue = {};
          this.items.forEach((item: FormItem) => {
            data[item.key] = this.form[item.key];
          });
          this.$emit("submit", data);
          resolve(data);
        }
      });
    },
    setForm() {
      let val = this.value;
      const config = this.config;
      if (val) {
        for (let key in val) {
          if (config.findIndex((c) => c.key == key) >= 0) {
            this.form[key] = val[key];
          }
        }
      } else {
        this.form = {};
      }
    },
    setItems() {
      const config = this.config;
      const items: Array<FormItem> = [];
      if (config) {
        config.forEach((item) => {
          if (
            !item.rely ||
            this.getRelyFlag(item.rely, this.form[item.rely.key])
          ) {
            items.push(item);
          }
        });
      }
      this.items = items;
    },
    setRely() {
      const rely = this.rely,
        config = this.config;
      const items: Array<FormItem> = [];
      if (config) {
        config.forEach((item) => {
          const key = item.key;
          if (item.component === "upload" && defaultKeyDemo[key]) {
            item.demo = defaultKeyDemo[key];
          }
          if (!item.rely || this.getRelyFlag(item.rely, this.form[key])) {
            items.push(item);
          }

          if (!item.labelStyle) {
            item.labelStyle = "margin-bottom: 20px;";
            if (item.component === "input") {
              item.labelStyle +=
                "background-color: #ffffff; border-radius: 12px;";
              if (item.type === "textarea") {
                item.labelStyle += "padding-top: 6px;";
              }
            } else if (item.component === "car") {
              item.label = "";
              item.labelStyle += "padding: 0;";
            } else if (
              item.component === "half-date" ||
              item.component === "date-time"
            ) {
              item.labelStyle +=
                "background-color: #ffffff; border-radius: 12px;";
            }
          }
          if (item.component === "car") {
            item.label = "";
          }
          if (!(item.component === "input" && item.type === "textarea")) {
            item.position = "left";
          }
          if (item.rely) {
            let key = item.rely.key;
            if (rely[key]) {
              rely[key].push(key);
            } else {
              rely[key] = [key];
            }
          }
        });
      } else {
        this.rely = {};
      }
      this.items = items;
    },
    getRelyFlag(rely: RelyConfig, value: unknown): boolean {
      let flag = false;
      let val = rely.value;
      if (val instanceof Array) {
        flag = val.indexOf(value) >= 0;
      } else {
        flag = value === val;
      }
      return flag;
    },
  },
});

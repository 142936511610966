import { defineComponent } from "vue";
import { SelectProps } from "@/ui/select/props";
import { CSelectData } from "@/ui/select/type";
import { Range } from "@/ui/picker/type";
import CPicker from "../picker";
import CIcon from "../icon";
import mixin from "../form/mixin";
import "./style/select.less";
import { isArray } from "@/common/tool";

export default defineComponent({
  name: "CSelect",
  components: {
    CPicker,
    CIcon,
  },
  mixins: [mixin],
  props: { ...SelectProps },
  data(): CSelectData {
    return {
      deptList: [],
    };
  },
  computed: {
    showValue(): string {
      let res = "";
      if (this.modelValue) {
        const mode = this.mode;
        let value;
        switch (mode) {
          case "time":
            value = this.modelValue as Array<Range>;
            res = value.join(":");
            break;
          case "date":
            value = this.modelValue as string;
            res = value;
            break;
          case "region":
            value = this.modelValue as Array<Range>;
            res = value.map((val) => this.getShowValue(val, "value")).join(" ");
            break;
          case "selector":
            value = this.modelValue as Range;
            res = this.getShowValue(value);
            break;
          case "multiSelector":
            if (isArray(this.modelValue)) {
              value = this.modelValue as Array<Range>;
              res = value.map((val) => this.getShowValue(val)).join(" ");
            }
            break;
        }
      }
      return res;
    },
    valueClass(): string {
      const showValue = this.showValue;
      if (showValue) {
        return "c-select-text";
      } else {
        return "c-select-text c-palace";
      }
    },
  },
  methods: {
    getShowValue(value: Range, rangeKey?: string): string {
      let res = "";
      rangeKey = rangeKey || this.rangeKey;
      if (typeof value === "object") {
        if (rangeKey) {
          res = String(value[rangeKey]);
        } else {
          res = value.toString();
        }
      } else {
        res = String(value);
      }
      return res;
    },
    handleUpdate(value: Range): void {
      this.$emit("update:modelValue", value);
      this.notifyCheck();
    },
  },
  render() {
    return (
      <c-picker
        class="c-input-box"
        range={this.options}
        title={this.placeholder}
        modelValue={this.modelValue}
        rangeKey={this.rangeKey}
        mode={this.mode}
        onUpdate:modelValue={this.handleUpdate}
      >
        <div class="c-input c-select">
          <div class={this.valueClass}>
            {this.showValue || this.placeholder}
          </div>
          <c-icon name="right" class="c-select-icon"></c-icon>
        </div>
      </c-picker>
    );
  },
});

import { defineComponent } from "vue";
import axios, { Canceler } from "axios";
import { ScrollView } from "../index";
import scrollListProps from "./props";
import { ScrollListData } from "./type";
import { EntityListData } from "@/api/type";
import "./style/scroll-list.less";

let canceler: Canceler | null = null;
export default defineComponent({
  name: "ScrollList",
  components: { ScrollView },
  props: { ...scrollListProps },
  data(): ScrollListData {
    return {
      list: null,
      page: 1,
      loading: false,
      more: true,
    };
  },
  watch: {
    query() {
      this.refresh();
    },
  },
  created(): void {
    this.getList();
  },
  methods: {
    renderEmpty() {
      return this.list ? <div class="tip no-data">{this.emptyText}</div> : null;
    },
    refresh() {
      this.cancelRequest();
      this.page = 1;
      this.loading = false;
      this.more = true;
      this.getList();
    },
    updateItem(item: unknown, index: number) {
      if (this.list) {
        this.list[index] = item;
      }
    },
    cancelRequest() {
      canceler?.();
      canceler = null;
    },
    getList() {
      let url = this.url;
      if (url) {
        if (this.loading || !this.more) return;
        this.loading = true;
        url += `?page=${this.page}&pageSize=${this.pageSize}`;
        const query = this.query || "";
        url += query;
        const config = Object.assign(
          {
            url,
            method: "GET",
            cancelToken: new axios.CancelToken((c) => {
              canceler = c;
            }),
          },
          this.requestConfig || {}
        );
        this.$http
          .request<EntityListData<unknown>>(config)
          .then((res) => {
            const data = res.data.data;
            if (this.page == 1) {
              this.list = data;
            } else {
              this.list?.push(...data);
            }
            const page = res.data.page;
            if (this.page >= page.pageCount) {
              this.more = false;
            } else {
              this.page += 1;
            }
          })
          .finally(() => {
            this.loading = false;
            canceler = null;
          });
      }
    },
  },
  render() {
    return (
      <div class="scroll-list">
        <div class="scroll-list-top" v-show={this.$slots.top}>
          {this.$slots.top?.()}
        </div>
        <scroll-view class="scroll-list-content" onScrollToLower={this.getList}>
          {this.$slots.prefix?.()}
          {this.list && this.list.length > 0
            ? this.$slots.content?.({ list: this.list })
            : this.renderEmpty()}
          {
            <div class="scroll-list-loading" v-show={this.loading}>
              <c-icon name="loading" />
            </div>
          }
        </scroll-view>
      </div>
    );
  },
});

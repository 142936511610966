import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { hideUI } from "@/ui";
import store from "@/store";
const base = "/";

// 路由历史记录
const paths = {
  back: history.state?.back,
  forward: history.state?.forward,
};

// 缓存的路由url
const cachePaths = [{ path: "/home", component: "HomeView" }];

store.commit(
  "setInclude",
  cachePaths.map((item) => item.component)
);

const routes: Array<RouteRecordRaw> = [
  {
    path: "/auth",
    name: "auth",
    component: () => import(/* webpackChunkName: "auth" */ "../views/Auth.vue"),
    meta: {
      title: "正在登陆...",
      open: true,
    },
  },
  {
    path: "/:alias",
    name: "alias",
    component: () =>
      import(/* webpackChunkName: "alias" */ "../views/activity/Alias.vue"),
    children: [
      {
        path: "",
        name: "send",
        component: () =>
          import(
            /* webpackChunkName: "send" */ "../views/activity/SendPage.vue"
          ),
        meta: {
          title: "有声邮局",
        },
      },
    ],
  },
  {
    path: "/card/:id",
    name: "card",
    component: () =>
      import(/* webpackChunkName: "card" */ "../views/CardView.vue"),
    meta: {
      title: "明信片",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "404" */ "../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

router.beforeEach((to, from, next) => {
  hideUI();
  // 返回上一个路由采用返回的过渡动画
  const back = paths.back === to.fullPath;
  if (back) {
    from.meta.transition = "slide-left";
    to.meta.transition = "slide-right";
  } else {
    from.meta.transition = "slide-right";
    to.meta.transition = "slide-left";
  }
  // 在缓存的路由页面后退则去掉缓存
  const index = cachePaths.findIndex((p) => p.path === from.fullPath);
  if (back && index >= 0) {
    store.commit("setExclude", [cachePaths[index].component]);
  } else {
    store.commit("setExclude", []);
  }
  const meta = to.meta;
  if (typeof meta === "object" && meta.title) {
    document.title = meta.title + "";
  }
  next();
});
router.afterEach(() => {
  paths.back = history.state.back;
  paths.forward = history.state.forward;
});
export default router;

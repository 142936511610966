import { App, createApp, Plugin } from "vue";
import Preview from "./preview";
import { PreviewMethod } from "./type";
type PreviewPlugin = Plugin & PreviewMethod;
type PreviewInstance = InstanceType<typeof Preview>;
let previewInstance: PreviewInstance | null = null;
let previewApp: App | null = null;

export const hidePreview = function () {
  if (previewInstance) {
    document.body.removeChild(previewInstance.$el);
    previewInstance = null;
  }
  if (previewApp) {
    previewApp.unmount();
    previewApp = null;
  }
};

const createPreview: PreviewMethod = (props) => {
  hidePreview();
  const options: vm.Data = { ...props };
  const box = document.createElement("div");
  previewApp = createApp(Preview, options);
  const instance: PreviewInstance = previewApp.mount(box) as PreviewInstance;
  document.body.appendChild(instance.$el);
  previewInstance = instance;
  previewInstance.showPreview();
};
const PreviewPlugin: PreviewPlugin = createPreview as PreviewPlugin;

PreviewPlugin.install = (app: App) => {
  app.config.globalProperties.$preview = PreviewPlugin;
};

export default PreviewPlugin;
declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $preview: PreviewMethod;
  }
}

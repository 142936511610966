import { defineComponent, Transition } from "vue";
import PopProps from "./props";
import { isArray } from "@/common/tool";

export default defineComponent({
  name: "CPop",
  components: {
    Transition,
  },
  props: { ...PopProps },
  data() {
    return {
      show: false,
      visible: false,
    };
  },
  computed: {
    contentClasses(): string {
      const res = ["c-cover-content"];
      if (this.visible) {
        res.push("cover-content-show");
      }
      const contentClass = this.contentClass;
      if (contentClass) {
        if (isArray(contentClass)) {
          res.push(...contentClass);
        } else {
          res.push(contentClass + "");
        }
      }
      return res.join(" ");
    },
  },
  watch: {
    modelValue() {
      this.setShow();
    },
  },
  mounted(): void {
    this.setShow();
  },
  methods: {
    setShow() {
      const modelValue = this.modelValue;
      if (modelValue) {
        this.showPop();
      } else {
        this.hidePop();
      }
    },
    showPop() {
      if (this.show) return;
      this.show = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.visible = true;
        }, 0);
      });
      this.$emit("update:modelValue", true);
    },
    hidePop() {
      if (!this.visible) return;
      this.visible = false;
      setTimeout(() => {
        this.show = false;
      }, 300);
      this.$emit("update:modelValue", false);
    },
  },
  render() {
    return (
      <div class="c-cover" v-show={this.show}>
        <transition name="fade">
          <div
            class="c-cover-back"
            onClick={this.hidePop}
            v-show={this.visible}
          ></div>
        </transition>
        <div class={this.contentClasses} style={this.contentStyle}>
          {this.$slots.default?.()}
        </div>
      </div>
    );
  },
});

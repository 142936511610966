import { FileItem } from "@/ui/upload/type";
import { Preview, Toast } from "@/ui";

export type FileType =
  | "image"
  | "audio"
  | "video"
  | "word"
  | "excel"
  | "ppt"
  | "pdf"
  | "text"
  | "unknown";
export type FileExtensionIconType = Record<
  Exclude<FileType, "unknown">,
  string[]
>;
export const fileExtensionIconType: FileExtensionIconType = {
  image: [
    ".pjp",
    ".jfif",
    "pjpeg",
    ".avif",
    ".ico",
    ".tiff",
    ".gif",
    ".svg",
    ".bmp",
    ".png",
    ".xbm",
    ".jxl",
    ".jpeg",
    ".svgz",
    ".jpg",
    ".webp",
  ],
  video: [".mp4", ".avi", ".wmv", ".mpg", ".mpeg", ".mov", ".flv"],
  audio: [".mp3", ".wav"],
  word: [".doc", ".docx"],
  excel: [".xls", ".xlsx"],
  ppt: [".ppt", ".pptx"],
  pdf: [".pdf"],
  text: [".txt"],
};

export const getSuffix = function (filename: string) {
  const pos = filename.lastIndexOf(".");
  let suffix = "";
  if (pos != -1) {
    suffix = filename.substring(pos);
  }
  return suffix;
};

export const getFileTypeIcon = function (path: string) {
  let type: FileType = "unknown";
  const extension = getSuffix(path);
  if (extension) {
    let key: keyof FileExtensionIconType;
    for (key in fileExtensionIconType) {
      const index = fileExtensionIconType[key].indexOf(extension);
      if (index >= 0) {
        type = key;
        break;
      }
    }
  }
  return type;
};

export const getUrlFileName = function (url: string) {
  const pos = url.lastIndexOf("/");
  if (pos >= 0) {
    return url.substring(pos + 1);
  } else {
    return url;
  }
};

export const previewFile = function (item: FileItem) {
  const url = item.url;
  const type = item.type || getFileTypeIcon(url);
  if (type == "image" || type == "video") {
    Preview({
      current: url,
      type,
    });
  } else {
    Toast("此文件暂时不支持预览");
  }
};

export const ICON_PREFIX =
  "https://lingyin-1301841918.cos.ap-shanghai.myqcloud.com/assets/visitor/file/";

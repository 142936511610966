import http from "../request";
import { EntityListData } from "../type";

export interface Activity {
  id: number;
  alias: string;
  name: string;
  info: string;
  img: string;
  qr_code: string;
  is_print: number;
  create_time: string;
  update_time: string;
}

const TABLE_URL = "/activity";

export interface ActivityApi {
  getActivityByAlias: (alias: string) => Promise<Activity | null>;
}

const activityApi: ActivityApi = {
  getActivityByAlias(alias) {
    const url = `${TABLE_URL}?pageSize=1&filter[alias]=${alias}`;
    return http.get<EntityListData<Activity>>(url).then((res) => {
      const list = res.data.data;
      return list.length > 0 ? list[0] : null;
    });
  },
};

export default activityApi;

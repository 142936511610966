import { PropType } from "vue";
import { PreviewProps } from "./type";

export default {
  /**
   * 预览对象类型 image图片 video视频
   */
  type: {
    type: String as PropType<PreviewProps["type"]>,
    default: "image",
  },
  /**
   * 当前预览链接
   */
  current: {
    type: String,
    required: true,
  },
  /**
   * 图片预览列表
   */
  urls: {
    type: Array as PropType<PreviewProps["urls"]>,
  },
};

import { App } from "vue";

import FormArea from "./form-area";
import ScrollView from "./scroll-view";
import TabBar from "./tab-bar";
import UserAvatar from "./user-avatar";
import ScrollList from "./scroll-list";
import PageLoading from "./page-loading";
import Car from "./car";
import HalfDate from "./half-date";
import DateTime from "./date-time";
import WavePlayer from "./wave-palyer";

export * from "./form-area";
export * from "./tab-bar";
export * from "./user-avatar";
export * from "./scroll-list";
export * from "./car";
export * from "./half-date";
export * from "./date-time";

export { FormArea, ScrollView, TabBar, UserAvatar, ScrollList };
export default function install(app: App) {
  app.component("ScrollView", ScrollView);
  app.component("ScrollList", ScrollList);
  app.component("PageLoading", PageLoading);
  app.component("CCar", Car);
  app.component("CHalfDate", HalfDate);
  app.component("CDateTime", DateTime);
  app.component("WavePlayer", WavePlayer);
}

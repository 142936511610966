
import { defineComponent } from "vue";
import CIcon from "../icon";
import ToastProps from "./props";
import { TypeIcon, CToastIconType } from "./type";
const typeIcon: TypeIcon = {
  success: "check",
  error: "error-circle-filled",
  loading: "loading",
  none: "",
};
export default defineComponent({
  name: "CToast",
  components: {
    CIcon,
  },
  props: { ...ToastProps },
  computed: {
    iconName(): string {
      const icon: CToastIconType = this.icon;
      if (this.icon) {
        return typeIcon[icon];
      } else {
        return "";
      }
    },
    text(): string {
      const icon: string = this.iconName;
      if (icon) {
        return this.title.slice(0, 7);
      } else {
        return this.title;
      }
    },
  },
});

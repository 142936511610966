import { defineComponent, Teleport, h, VNode } from "vue";
import PreviewProps from "./props";
import { PreviewData, TransformStore } from "./type";
import { getTouchMoveDirection, getDistance } from "./util";
import "./style/preview.less";
import CIcon from "../icon";
import { ActionSheet } from "../index";

// 屏幕宽度
const sh = window.innerHeight;
const store: TransformStore = {
  originScale: 1,
  originX: 0,
  originY: 0,
  scale: 0,
  x: 0,
  y: 0,
  imgWidth: 0,
  imgHeight: 0,
  maxScale: 1,
  timestamp: 0,
};
let touchList: TouchList;
let direction = 0;

export default defineComponent({
  name: "CPreview",
  components: { CIcon },
  props: { ...PreviewProps },
  data(): PreviewData {
    return {
      index: 0,
      show: false,
      loading: true,
      imageStyle: { visibility: "hidden" },
      backStyle: { opacity: 0 },
    };
  },
  computed: {
    imgList(): Array<string> {
      const type = this.type;
      const current = this.current as string;
      const urls = this.urls || [];
      if (type === "image") {
        const index = urls.indexOf(current);
        if (index === -1) {
          urls.unshift(current);
        }
      }
      return urls;
    },
  },
  methods: {
    showPreview() {
      this.show = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.backStyle.transition = "opacity .3s";
          this.backStyle.opacity = 1;
        }, 0);
      });
    },
    hidePreview() {
      this.backStyle.transition = "opacity .3s";
      this.backStyle.opacity = 0;
      this.imageStyle.transition = "transform .3s";
      this.imageStyle.transformOrigin = "center";
      this.imageStyle.transform = "translateX(0) translateY(0) scale(0)";
      setTimeout(() => {
        this.show = false;
      }, 300);
    },
    imgLoaded(e: Event) {
      const img = e.currentTarget as HTMLImageElement;
      const { width, height } = img;
      const { innerWidth, innerHeight } = window;
      const imageScale = height / width;
      const windowScale = innerHeight / innerWidth;
      const imageStyle = { ...this.imageStyle };
      let imgWidth: number, imgHeight: number;
      if (imageScale <= windowScale) {
        if (width <= innerWidth) {
          imgWidth = width;
          imgHeight = height;
        } else {
          store.maxScale = width / innerWidth;
          imgWidth = innerWidth;
          imgHeight = innerWidth * imageScale;
        }
      } else {
        if (height <= innerHeight) {
          imgWidth = width;
          imgHeight = height;
        } else {
          store.maxScale = height / innerHeight;
          imgWidth = innerHeight / imageScale;
          imgHeight = innerHeight;
        }
      }
      imageStyle.visibility = "visible";
      imageStyle.width = `${imgWidth}px`;
      imageStyle.height = `${imgHeight}px`;
      store.imgWidth = imgWidth;
      store.imgHeight = imgHeight;
      this.imageStyle = imageStyle;
      this.loading = false;
    },
    renderVideo() {
      return this.show ? (
        <div class="preview-video-box" style={this.backStyle}>
          <video
            class="preview-video"
            autoplay
            controls
            webkit-playsinline
            playsinline
            x5-video-player-type="h5"
            x5-video-player-fullscreen="true"
            x-webkit-airplay="allow"
            x5-video-orientation="portraint"
            src={this.current}
          ></video>
          <c-icon
            name="close"
            class="preview-video-close"
            onClick={this.hidePreview}
          ></c-icon>
        </div>
      ) : null;
    },
    resetImage() {
      store.originScale = 1;
      store.scale = 0;
      store.originX = 0;
      store.originY = 0;
      store.x = 0;
      store.y = 0;
    },
    dealTwoTouchStart() {
      store.originScale = store.scale === 0 ? 1 : store.scale;
      this.imageStyle.transformOrigin = "center";
      this.imageStyle.transition = undefined;
      this.backStyle.transition = undefined;
    },
    dealTwoTouchMove(touches: TouchList) {
      const m1 = touches[0],
        m2 = touches[1];
      const s1 = touchList[0];
      const s2 = touchList[1];
      if (m1 && m2) {
        const zoom =
          getDistance(
            { x: m1.pageX, y: m1.pageY },
            { x: m2.pageX, y: m2.pageY }
          ) /
          getDistance(
            { x: s1.pageX, y: s1.pageY },
            { x: s2.pageX, y: s2.pageY }
          );
        store.scale = store.originScale * zoom;
        this.imageStyle.transform = `translateX(${store.x}px) translateY(${store.y}px) scale(${store.scale})`;
      }
    },
    dealTwoTouchEnd() {
      if (store.scale < 1) {
        this.resetImage();
        this.imageStyle.transform = `translateX(${store.x}px) translateY(${store.y}px) scale(${store.originScale})`;
        this.imageStyle.transition = "transform .3s";
      } else if (store.scale > store.maxScale) {
        store.scale = store.maxScale;
        this.imageStyle.transform = `translateX(${store.x}px) translateY(${store.y}px) scale(${store.scale})`;
        this.imageStyle.transition = "transform .3s";
      }
    },
    dealOneTouchStart() {
      direction = 0;
      this.imageStyle.transition = undefined;
      //不是缩放状态下的单指滑动
      if (store.scale === 0) {
        this.imageStyle.transformOrigin = "top center";
        this.imageStyle.transform = undefined;
      } else {
        store.originX = store.x;
        store.originY = store.y;
      }
    },
    dealOneTouchMove(touches: TouchList) {
      const startTouch = touchList[0];
      const touch = touches[0];
      if (startTouch && touch) {
        const { pageX: startX, pageY: startY } = startTouch;
        const { pageX, pageY } = touch;
        const movedX = pageX - startX;
        const movedY = pageY - startY;
        if (direction == 0 && movedX !== 0 && movedY !== 0) {
          direction = getTouchMoveDirection(movedX, movedY);
        }
        //不是缩放状态下的单指滑动
        if (store.scale === 0) {
          // 向下滑动
          if (direction === 2) {
            let scale = 1 - movedY / sh;
            scale = scale > 1 ? 1 : scale;
            store.x = movedX;
            store.y = movedY;
            this.imageStyle.transform = `translateX(${movedX}px) translateY(${movedY}px) scale(${scale})`;
            this.backStyle.opacity = scale;
          }
        } else {
          store.x = store.originX + movedX;
          store.y = store.originY + movedY;
          this.imageStyle.transform = `translateX(${store.x}px) translateY(${store.y}px) scale(${store.scale})`;
        }
      }
    },
    dealOneTouchEnd(timestamp: number) {
      //不是缩放状态下的单指滑动
      if (store.scale === 0) {
        if (direction === 0) {
          // 大于300 ms 为长按
          const timeOffset = timestamp - store.timestamp;
          if (timeOffset > 300) {
            this.showOperateAction();
          } else {
            this.hidePreview();
          }
        } else {
          // 向下滑动图片隐藏
          if (store.y > 0) {
            this.hidePreview();
          } else {
            this.imageStyle.transform = "translateX(0) translateY(0)";
            this.imageStyle.transition = "transform .3s";
            this.backStyle.opacity = 1;
            this.backStyle.transition = "opacity .3s";
          }
          // 偏移量置为初始值
          store.x = 0;
          store.y = 0;
        }
      } else {
        // 缩放状态下单指未移动
        if (store.x === store.originX && store.y === store.originY) {
          this.hidePreview();
        }
      }
    },
    handleTouchStart(e: TouchEvent) {
      e.stopPropagation();
      e.preventDefault();
      store.timestamp = e.timeStamp;
      touchList = e.touches;
      switch (touchList.length) {
        case 1:
          this.dealOneTouchStart();
          break;
        case 2:
          this.dealTwoTouchStart();
          break;
      }
    },
    handleTouchMove(e: TouchEvent) {
      e.stopPropagation();
      e.preventDefault();
      const touches = e.touches;
      switch (touchList.length) {
        case 1:
          this.dealOneTouchMove(touches);
          break;
        case 2:
          this.dealTwoTouchMove(touches);
          break;
      }
    },
    handleTouchEnd(e: TouchEvent) {
      e.stopPropagation();
      e.preventDefault();
      switch (touchList.length) {
        case 1:
          this.dealOneTouchEnd(e.timeStamp);
          break;
        case 2:
          this.dealTwoTouchEnd();
          break;
      }
    },
    showOperateAction() {
      ActionSheet({
        itemList: ["保存图片"],
        fn: (index) => {
          if (index == 0) {
            //todo
            console.log("保存图片");
          }
        },
      });
    },
    renderImage() {
      const imgList = this.imgList;
      return (
        <div class="preview-image-box" onClick={this.hidePreview}>
          <img
            src={this.current}
            class="preview-image"
            style={this.imageStyle}
            onTouchstart={this.handleTouchStart}
            onTouchmove={this.handleTouchMove}
            onTouchend={this.handleTouchEnd}
            onLoad={this.imgLoaded}
          />
          <c-icon
            name="loading"
            class="preview-image-loading"
            v-show={this.loading}
          />
          {imgList.length > 1 ? <div class="preview-image-dots"></div> : null}
        </div>
      );
    },
    renderMedia(): VNode | null {
      const type = this.type;
      if (type == "video") {
        return this.renderVideo();
      } else {
        return this.renderImage();
      }
    },
  },
  render() {
    return h(
      Teleport,
      { to: "body" },
      <div class="preview-box" v-show={this.show}>
        <div class="preview-box-back" style={this.backStyle}></div>
        {this.renderMedia()}
      </div>
    );
  },
});


import { defineComponent, ref, toRef, computed, watch } from "vue";
import DateTimeProps from "./props";

export default defineComponent({
  name: "dateTime",
  props: { ...DateTimeProps },
  setup(props, { emit }) {
    const date = ref("");
    const time = ref<string[] | null>(null);
    const modelValue = toRef(props, "modelValue");
    const value = computed<string>({
      get() {
        let res = "";
        if (date.value && time.value) {
          res = date.value + " " + (time.value as string[]).join(":");
        }
        return res;
      },
      set(val) {
        if (val) {
          let arr = val.split(" ");
          date.value = String(arr[0]);
          time.value = (arr[1] as string).split(":");
        } else {
          date.value = "";
          time.value = null;
        }
      },
    });
    const setValue = () => {
      value.value = modelValue.value || "";
    };
    setValue();
    watch(modelValue, setValue);
    watch(value, () => {
      emit("update:modelValue", value.value);
    });
    return {
      date,
      time,
    };
  },
});
